import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV1 from "./components/home-v1";
import Service from "./components/service";
import PortfolioDetails from "./components/portfolio-details";
import Team from "./components/team";
import TeamDetails from "./components/team-details";
import Faq from "./components/faq";
import ProductDetails from "./components/product-details";
import ShopLeftSidebar from "./components/shop-left-sidebar";
import BlogLeftSidebar from "./components/blog-left-sidebar";
import BlogDetails from "./components/blog-details";
import Contact from "./components/contact";
import Disclaimer from "./components/history";

class Root extends Component {
  render() {
    return (
      <Router basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/service" component={Service} />
            <Route path="/listings" component={ShopLeftSidebar} />
            <Route path="/all-news" component={BlogLeftSidebar} />
            <Route path="/team" component={Team} />
            <Route path="/contact" component={Contact} />
            <Route path="/faq" component={Faq} />
            <Route path="/product-details/:id" component={ProductDetails} />
            <Route path="/team-details/:id" component={TeamDetails} />
            <Route path="/privacy-policy" component={PortfolioDetails} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route path="/blog-details/:id" component={BlogDetails} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
