import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

class FaqV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__faq-area mb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="ltn__faq-inner ltn__faq-inner-2">
                            <div id="accordion_2">
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-1" aria-expanded="false">
                                        What is Moovmynt?

                                    </h6>
                                    <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Moovmynt is a platform designed to simplify access to pre-sale real estate opportunities. It provides comprehensive tools for buyers, REALTORS®, and investors to discover, analyze, and plan for pre-sale property purchases with transparency and ease.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-2" aria-expanded="false">
                                        What is a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>A pre-sale property refers to a real estate unit available for purchase before it is fully built or completed. This often includes developments still under construction or even in the planning stages.

Additionally, pre-sale properties may include newly completed buildings where some units remain unsold.
</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-3" aria-expanded="false">
                                 What are the benefits and risks of buying a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                            <h6>Benefits:</h6>
<ul>
    <li><strong>New Home:</strong> You’ll be the first owner, with no need for renovations before moving in.</li>
    <li><strong>Price Advantage:</strong> Purchase at today’s price, while the property value often appreciates during construction.</li>
    <li><strong>Customization:</strong> Some developers offer options to customize finishes and layouts during construction.</li>
    <li><strong>Financial Planning:</strong> Pre-sale properties typically require smaller deposits spread out over time, giving buyers time to save.</li>
    <li><strong>Warranties:</strong> Many pre-sales come with a 2-5-10 warranty in Canada, covering defects and structural issues.</li>
</ul>

<h6>Risks:</h6>
<ul>
    <li><strong>Delays:</strong> Construction may face delays, causing possession dates to shift by months or years.</li>
    <li><strong>Financial Risks:</strong> If your financial situation changes before completion, you might struggle to secure a mortgage.</li>
    <li><strong>Non-Refundable Deposits:</strong> Backing out of a pre-sale purchase can be challenging and may result in forfeiting your deposit.</li>
    <li><strong>Market Fluctuations:</strong> Property values may decline during the construction period, reducing the expected appreciation.</li>
</ul>

                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-4" aria-expanded="false">
                                 How much do I need to pay upfront for a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                                
                                            <h6>Pre-Sale Purchases Generally Require:</h6>
<ul>
    <li>An initial deposit of <strong>5-10%</strong> of the purchase price upon signing the agreement.</li>
    <li>Additional deposits over <strong>12-18 months</strong>, totaling <strong>15-25%</strong> of the purchase price.</li>
</ul>

<p>The remaining balance (typically <strong>75-85%</strong>) is due at completion when you secure a mortgage. Deposits vary depending on the developer, project, and agreement terms.</p>

<h6>Example:</h6>
<p>
    A <strong>$500,000</strong> property may require:
</p>
<ul>
    <li>An initial deposit of <strong>$25,000 to $50,000</strong>,</li>
    <li>With further payments scheduled over time.</li>
</ul>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-5" aria-expanded="false">
                               When do I start paying my mortgage on a pre-sale property?

                                    </h6>
                                    <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>Mortgage payments typically begin once the property is complete and ready for possession. Before this, you only pay deposits as outlined in your pre-sale agreement.
<br/>
Tip: It’s a good idea to pre-plan your mortgage and understand financing options to ensure a smooth process at completion.
</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-6" aria-expanded="false">
                                        Are there any taxes when buying a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-6" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p><h6>Taxes on Pre-Sale Properties in Canada:</h6>
<ul>
    <li><strong>5% GST (Goods and Services Tax):</strong> Applied to the purchase price of new homes.</li>
    <li><strong>Property Transfer Tax (PTT):</strong>
        <ul>
            <li><strong>1%</strong> on the first $200,000</li>
            <li><strong>2%</strong> on the portion between $200,000 and $2,000,000</li>
            <li><strong>3%</strong> on any amount above $2,000,000</li>
        </ul>
    </li>
    <li><strong>First-Time Homebuyer Exemption:</strong> For qualifying buyers, properties priced under $500,000 may be exempt from Property Transfer Tax.</li>
</ul>

<p><strong>Disclaimer:</strong> Taxes vary by region and individual circumstances. Moovmynt is not a tax advisor. You should consult a qualified tax professional for personalized guidance.</p>
</p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-7" aria-expanded="false">
                                      Can I sell or transfer my pre-sale agreement before completion?
                                    </h6>
                                    <div id="faq-item-2-7" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                            <h6>Assignment Sale:</h6>
<p>This is when you sell your contract (and deposit paid) to a new buyer before the project is complete.</p>

<h6>Key Considerations:</h6>
<ul>
    <li>Assignment sales may require the developer’s approval and often include administrative fees.</li>
    <li>The new buyer assumes responsibility for the remaining deposit schedule and final payment.</li>
    <li>Profit from the assignment is taxable and subject to GST.</li>
</ul>

<p><strong>Tip:</strong> Always review your contract and consult a REALTOR® or legal professional for guidance.</p>


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-8" aria-expanded="false">
                                    How do I know if a pre-sale property is a good investment?
                                    </h6>
                                    <div id="faq-item-2-8" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                            <h6>Key Factors to Evaluate Pre-Sale Opportunities:</h6>
<ul>
    <li><strong>Location:</strong> Is the development in a high-growth area with potential for appreciation?</li>
    <li><strong>Developer Reputation:</strong> Research the developer’s past projects and completion record.</li>
    <li><strong>Market Trends:</strong> Assess property values, rental demand, and future development plans in the area.</li>
    <li><strong>Financial Returns:</strong> Use tools like ROI calculators to estimate your potential return on investment.</li>
</ul>


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-9" aria-expanded="false">
                                        What happens if the pre-sale project is delayed or cancelled?
                                    </h6>
                                    <div id="faq-item-2-9" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                                
                                            <h6>Potential Issues with Pre-Sale Purchases:</h6>
<ul>
    <li><strong>Delays:</strong> Developers are often allowed to extend completion dates as per the purchase agreement.</li>
    <li><strong>Cancellations:</strong> In rare cases, buyers are refunded their deposits if a project is cancelled. However, compensation for interest or lost opportunities is not guaranteed.</li>
</ul>

<p><strong>Important:</strong> Always review the contract terms carefully and consult legal professionals for added protection.</p>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* card */}
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-10" aria-expanded="false">
                                   How do I get started with purchasing a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-10" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                            <h6>Steps for a Successful Pre-Sale Purchase:</h6>
                                                <ul>
                                                    <li><strong>Research:</strong> Explore pre-sale developments in desirable areas with strong growth potential.</li>
                                                    <li><strong>Understand the Costs:</strong> Be aware of deposits, taxes, and closing fees.</li>
                                                    <li><strong>Secure Financing:</strong> Get pre-approved for a mortgage to confirm your purchasing power.</li>
                                                    <li><strong>Review the Agreement:</strong> Understand the purchase terms, timelines, and risks.</li>
                                                    <li><strong>Work with Experts:</strong> Partner with experienced REALTORS®, legal advisors, and mortgage brokers to ensure a smooth transaction.</li>
                                                </ul>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-11" aria-expanded="false">
                                          Can I customize a pre-sale property?
                                    </h6>
                                    <div id="faq-item-2-11" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                                
                                                                                    <h6>Customization Options Available During Early Phases of Construction:</h6>
                                        <ul>
                                            <li><strong>Flooring, cabinets, and countertops</strong></li>
                                            <li><strong>Appliances and fixtures</strong></li>
                                            <li><strong>Layout adjustments</strong> (depending on the project)</li>
                                        </ul>

                                        <p>Customization availability and deadlines vary by developer, so confirm your preferences as early as possible.</p>
                                                                                    </p>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="card">
                                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse"
                                        data-bs-target="#faq-item-2-12" aria-expanded="false">
                                          Disclaimer
                                    </h6>
                                    <div id="faq-item-2-12" className="collapse" data-bs-parent="#accordion_2">
                                        <div className="card-body">
                                            <p>
                                        
                                            The information provided on this website, including all content related to Moovmynt Technology Inc, MoovAI, and the entirety of Moovmynt, is for general informational purposes only and should not be considered financial, legal, or tax advice. Moovmynt does not guarantee the accuracy, completeness, or reliability of any information contained herein. You should consult with qualified professionals, such as real estate agents, legal advisors, tax professionals, and mortgage specialists, before making any real estate decisions.
<br/>
Moovmynt Technology Inc, MoovAI, and the entirety of Moovmynt are not responsible or liable for any loss, damages, or decisions made based on the information provided. All real estate transactions carry risks, and it is the responsibility of the user to verify all details independently.

                                                                                    </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="need-support text-center mt-100">
                                <h2>Still need help? Reach out to support 24/7:</h2>
                                <div className="btn-wrapper mb-30 go-top">
                                    <Link to="/contact" className="theme-btn-1 btn">Contact Us</Link>
                                </div>
                                <h3><i className="fas fa-phone"/> +1 604-283-7610</h3>
                            </div>
                        </div>
                    </div>
                     <div className="col-lg-2"></div>

                </div>
            </div>
        </div>

    }
}

export default FaqV2