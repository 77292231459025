import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

const EnableScrollZoom = () => {
  const map = useMap();

  useEffect(() => {
    map.scrollWheelZoom.enable(); // Explicitly enable scroll zoom
    return () => {
      map.scrollWheelZoom.disable(); // Clean up on unmount
    };
  }, [map]);

  return null;
};

const Location = () => {
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const center = [49.24725, -123.14435];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/properties/top-locations/"
        );
        const data = await response.json();
        const properties = data.map((property) => ({
          id: property.id,
          name: property.name,
          price: property.price,
          address: property.address,
          property_type: property.property_type,
          developer: property.developer,
          city: property.city,
          thumbnail: property.thumbnail,
          position: [property.latitude, property.longitude],
        }));
        setPropertyDetailsList(properties);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="m-5" style={{ position: "relative" }}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "4px solid #ccc",
              borderTop: "4px solid #333",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      )}
      <MapContainer
        center={center}
        zoom={11}
        style={{
          height: "650px",
          width: "100%",
        }}
        minZoom={2}
        maxZoom={18}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true}
        />
        <EnableScrollZoom />
        {!isLoading && (
          <MarkerClusterGroup>
            {propertyDetailsList.map((property) => (
              <Marker key={property.id} position={property.position}>
                <Popup closeButton={false}>
                  <div
                    style={{
                      pointerEvents: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={property.thumbnail}
                      alt={property.name}
                      style={{
                        width: "50%",
                        height: "50%",
                        marginTop: 15,
                        marginBottom: 10,
                      }}
                    />
                    <div
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        {property.name} | ( {property.property_type}) | ({" "}
                        {property.developer &&
                        property.developer !== "Unknown Developer"
                          ? property.developer
                          : "TBD" }
                        )
                      </p>
                      <p>
                        {property.address}, {property.city}
                      </p>
                      <p
                                       className={
                                        property.price === 0
                                          ? ""
                                          : property.price > 3000000
                                          ? "dark-red"
                                          : property.price >= 2000000
                                          ? "blue"
                                          : "green"
                                      }
                      >
                        Price:{" "}
                        {property.price === 0
                          ? "TBD"
                          : `$${property.price.toLocaleString()}`}{" "}
                           </p>
                           <p>
                        <Link
                          to={`/product-details/${property.id}`}
                          style={{ cursor: "pointer" }}
                        >
                          View Details
                        </Link>
                      </p>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        )}
      </MapContainer>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default Location;
