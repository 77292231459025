import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Comments from "../../blog-components/comments";

const GetNesDetails = ({ id }) => {
  const [News, setNews] = useState({});
  const publicUrl = process.env.PUBLIC_URL + '/';

  useEffect(() => {
    // Fetch the team members from your API
    axios.get(`https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/news-api/${id}`)  // Replace with your actual API endpoint
      .then(response => {
        console.log('News details are:', response);
        setNews(response.data);
      })
      .catch(error => {
        console.error('Error fetching the team members:', error);
      });
  }, [id]);

  console.log('teamMember set is:', News);

  return (
   <div className="ltn__blog-details-wrap">
					<div className="ltn__page-details-inner ltn__blog-details-inner">

					<h2 className="ltn__blog-title">{News.news_title}</h2>
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" />By: Admin</Link>
						</li>
						<li className="ltn__blog-date">
							<i className="far fa-calendar-alt" />{new Date(News.created_on).toLocaleDateString()}
						</li>
						</ul>
					</div>

					<img src={News.image} alt="Image" />

					<p>{News.details}</p>

					</div>
					{/* blog-tags-social-media */}
					<div className="ltn__blog-tags-social-media mt-80 row">


					</div>
					{/* <hr /> */}
					{/* prev-next-btn */}
					{/*<div className="ltn__prev-next-btn row mb-50">*/}
					{/*<div className="blog-prev col-lg-6">*/}
					{/*	<h6>Prev Post</h6>*/}
					{/*	<h3 className="ltn__blog-title"><Link to="#">Tips On Minimalist</Link></h3>*/}
					{/*</div>*/}
					{/*<div className="blog-prev blog-next text-right text-end col-lg-6">*/}
					{/*	<h6>Next Post</h6>*/}
					{/*	<h3 className="ltn__blog-title"><Link to="#">Less Is More</Link></h3>*/}
					{/*</div>*/}
					{/*</div>*/}

					{/* <Comments/> */}
				</div>
  );
};

export default GetNesDetails;