import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const FetchBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blog data from the API
    axios.get('https://moovmynt-backend-7a58dd9e9029.herokuapp.com/home/all-news-api/')
      .then(response => {
        console.log('Data fetching from news api:',response.data.results);
        setBlogs(response.data.results);
      })
      .catch(error => {
        console.error("Error fetching blogs:", error);
      });
  }, []);

  return (
    <div className="row ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
      {blogs.map((blog, index) => (
        <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
          <div className="ltn__blog-item ltn__blog-item-3">
            <div className="ltn__blog-img">
              <Link to={`/blog-details/${blog.slug}`}>
                <img src={blog.image || 'assets/img/blog/default.jpg'} alt={blog.news_title} style={{ width:"100%",height:"250px" }} />
              </Link>
            </div>
            <div className="ltn__blog-brief">
              <div className="ltn__blog-meta">
                <ul>


                </ul>
              </div>
              <h3 className="ltn__blog-title" style={{ fontSize:"16px" }}>
                <Link to={`/blog-details/${blog.slug}`}>{blog.news_title}</Link>
              </h3>
              <div className="ltn__blog-meta-btn">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />{new Date(blog.created_on).toLocaleDateString()}
                    </li>
                  </ul>
                </div>
                <div className="ltn__blog-btn">
                  <Link to={`/blog-details/${blog.slug}`}>Read more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FetchBlogs;
