import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import Aboutv1 from "./section-components/about-v1";
import Counter from "./section-components/counter-v1";
import Featuresv1 from "./section-components/features-v1";
import Testimonial from "./section-components/testimonial-v1";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import Availability from "./section-components/availability";
import Location from "./section-components/location";

const Home_V1 = () => {
  return (
    <div>
      <Navbar />
      <Location />
      <Availability />
      <Banner />
      <Aboutv1 />
      <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      <Counter />
      <Testimonial />
      <BlogSlider customClass="section-subtitle-2" />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V1;
