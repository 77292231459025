import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FetchTopFiveBlog from "./fetch-latest-blog-top-five";



class Sidebar extends Component {
  render() {
   let publicUrl = process.env.PUBLIC_URL+'/'
    return (
		<div className="col-lg-4 go-top">
			<aside className="sidebar-area blog-sidebar ltn__right-sidebar">

				{/* Search Widget */}
				{/* <div className="widget ltn__search-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Search Objects</h4>
				<form action="#">
					<input type="text" name="search" placeholder="Search your keyword..." />
					<button type="submit"><i className="fas fa-search" /></button>
				</form>
				</div> */}
				{/* Form Widget */}
				<div className="widget ltn__form-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Messege For Book</h4>
				<form action="#">
					<input type="text" name="yourname" placeholder="Your Name*" />
					<input type="text" name="youremail" placeholder="Your e-Mail*" />
					<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
					<button type="submit" className="btn theme-btn-1">Send Messege</button>
				</form>
				</div>



				<div className="widget ltn__popular-post-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Latest Blogs</h4>
				<FetchTopFiveBlog/>
				</div>

				<div className="widget ltn__social-media-widget">
				<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
				<div className="ltn__social-media-2">
					<ul>
					<li><a href="https://www.facebook.com/Moovmynt-101542262090927" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
					<li><a href="https://x.com/i/flow/login?redirect_after_login=%2FMoovmynt" title="Twitter"><i className="fab fa-twitter" /></a></li>
					<li><a href="https://www.youtube.com/channel/UCjiAgIeiBd6oDX0GzF-6ZBQ" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
					<li><a href="https://www.instagram.com/moovmynt/" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
				</div>
				</div>

				<div className="widget ltn__banner-widget d-none go-top">
				<Link to="/shop"><img src={publicUrl+"assets/img/banner/2.jpg"} alt="#" /></Link>
				</div>
			</aside>
		</div>
    )
  }
}

export default Sidebar;
