const cities = [
  "SURREY, BC",
  "LANGLEY, BC",
  "VANCOUVER, BC",
  "NORTH VANCOUVER, BC",
  "WEST VANCOUVER, BC",
  "BURNABY, BC",
  "COQUITLAM, BC",
  "RICHMOND, BC",
  "PORT COQUITLAM, BC",
  "NEW WESTMINSTER, BC",
  "DELTA, BC",
  "ABBOTSFORD, BC",
  "CHILLIWACK, BC",
  "MAPLE RIDGE, BC",
  "WHITE ROCK, BC",
  "MISSION, BC",
  "HOPE, BC",
  "PRINCE GEORGE, BC",
  "LANGLEY TOWNSHIP, BC",
  "LANGLEY CITY, BC",
  "PORT MOODY, BC",
  "TSAWWASSEN, BC",
  "UNIVERSITY ENDOWMENT LANDS, BC",
  "METRO VANCOUVER, BC",
  "PITT MEADOWS, BC",
];

export default cities;
