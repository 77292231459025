const developers = [
  "Anthem",
  "Bosa Development",
  "British Pacific Properties",
  "Brimming Development",
  "Fastmark Development Corp",
  "Wesgroup Properties",
  "Isle of Mann Property Group",
  "Foxridge Homes",
  "Tera Development",
  "Miracon",
  "BMG Real Estate",
  "Zenterra",
  "Belford Properties",
  "Kirpal Group",
  "Allaire",
  "NAM (Moodyville) Development Ltd.",
  "Stanton Development",
  "Illuma Development",
  "Morguard",
  "Intergulf Development Group",
  "Marcon",
  "Westbank and QuadReal",
  "Dava Developments",
  "Porte Homes",
  "Rize",
  "Vanhome Properties Inc.",
  "Pinnacle International",
  "Canderel Residential",
  "Apna Group",
  "Three Shores",
  "Cressey",
  "Wave Developments Ltd.",
  "Kerkhoff Develop-Build",
  "Beedie Living",
  "Solterra Group of Companies",
  "British Pacific Properties and Beedie Living",
  "Westland",
  "Pennyfarthing Homes",
  "Crescent Legacy Properties",
  "Polygon Homes",
  "Peterson and Create Properties",
  "Ledingham McAllister",
  "Vansoho Development",
  "Noort Homes",
  "Genaris Properties",
  "First Track Development",
  "Listraor and Locarno Legacy",
  "Concert Properties",
  "StreetSide Developments (British Columbia)",
  "Forest International Real Estate Investment Company Ltd.",
  "Saiya Developments",
  "Everglade Development",
  "Concord Pacific",
  "Fully Homes",
  "Vanquish Homes",
  "Open Concept",
  "Vesterra Properties",
  "Onni Group",
  "Asia Standard Americas",
  "Bene Group",
  "Epic Homes",
  "Amacon",
  "AviSina Developments Inc.",
  "Quadra Homes",
  "PC Urban",
  "Vesta Properties",
  "ZAKO Development INC.",
  "Bosa Properties and Kingswood Properties",
  "Westmark Group",
  "Grosvenor Pacific Development Limited and Transca Real Estate Development",
  "Accor Group",
  "Vansoho Development, Marine Vista Group and LQ Fairview Holding LTD.",
  "Anthem and Kingsett Capital",
  "Icona Properties",
  "Westbank",
  "Essence Properties",
  "JPS Developments Inc.",
  "Townline and QuadReal",
  "Amson Group",
  "iFortune",
  "Bosa Properties and Vanprop Investments Ltd",
  "Urban Coast Developments Ltd.",
  "Bucci",
  "Square Nine Developments Inc.",
  "Enrich Developments",
  "Forte Living",
  "Bold Properties",
  "Gryphon Development",
  "Alvair Group",
  "Dimex Group",
  "Wanson Group",
  "Woodlock Developments",
  "West Fraser Developments Ltd",
  "Altos Properties Corp.",
  "Matchpoint Development",
  "Woodbridge Homes",
  "Grand Long",
  "Whitetail Homes",
  "Golden Glory Development",
  "Foster Living",
  "Domus Homes",
  "CCR Projects",
  "Citimark and Grosvenor",
  "Cadillac Fairview, SHAPE and Axiom Builders",
  "Aragon",
  "Woodbridge Homes and Citimark",
  "RK Development Group, Redekop Kroeker Development Inc. and RF Properties",
  "Crescent Creek Homes",
  "Maskeen",
  "Killarney Group",
  "Intracorp",
  "Gardenia Homes",
  "Pollyco Group of Companies",
  "Western Canada Monthly Income Fund",
  "Keltic Canada Development",
  "Crombie REIT",
  "Noura Homes",
  "Lotus Capital and Omicron",
  "Ankenman Marchand Architects",
  "Annesley Homes",
  "Sivia Construction",
  "Inception Group",
  "Everbright Properties",
  "Ardor Homes",
  "Peterson",
  "Raicon",
  "Raichu Development Group",
  "Transca Real Estate Development",
  "Weststone Group",
  "Headwater Projects",
  "Wall Financial Corporation",
  "Jayen Properties",
  "PureWest",
  "Townline",
  "Pakland Homes",
  "Yuanheng",
  "Canada West Group",
  "Mainland Developments",
  "Pacific Lyte Asset Management",
  "Kenstone Properties and Axiom Construction",
  "Staburn Group",
  "Mosaic Homes",
  "Forme Development",
  "Creo Developments",
  "Aquilini Development",
  "Citrine Homes",
  "AcePoint",
  "Canadian Metropolitan Properties Corp.",
  "First Track Development and Cantera Group",
  "FOREFIELD Development Group",
  "Baydo and Pacific Properties",
  "SHAPE",
  "Qualico Living and StreetSide Developments (British Columbia)",
  "Tangerine Developments",
  "Listraor",
  "AcePoint and His Grace Homes",
  "Dawson + Sawyer",
  "Aria Pacific Development Inc.",
  "Adera",
  "Elegant Development Inc.",
  "Western",
  "Quarry Rock Developments",
  "Youngearth Developments Ltd.",
  "Konic Homes and MCS Developments",
  "South Street Development Group",
  "Strand",
  "Grosvenor",
  "Hawthorne Developments",
  "Nexst Properties",
  "OVI Homes Ltd. and Kennedy Development",
  "Distrikt Homes",
  "Shato Holdings Ltd.",
  "Sightline Properties",
  "Kaval Properties",
  "Omicron",
  "Thind",
  "Eunoia Homes Ltd.",
  "Century Group",
  "Executive Group Development",
  "Hudson Projects",
  "Vanstruction Group and Jagpal Development Ltd.",
  "Bogner Development Group Ltd.",
  "Aspac Developments",
  "Kind Development Group",
  "Oviedo Properties",
  "Marvel Group",
  "Qualico Communities",
  "Bosa Properties",
  "Archstone",
  "Rendition Developments",
  "VSP Developments",
  "Cascadia Green Development",
  "Contour Development",
  "Landvision Group",
  "Valour Development Group",
  "Northwest Development",
  "Platinum Group",
  "Steelix",
  "Prima Properties Ltd.",
  "Aspen Enterprises",
  "Careston Properties",
  "Paddington Properties",
  "Quad City Real Estate Group and Eleven Oaks Development",
  "Sync Properties",
  "CastleHill Homes",
  "Radiance Developments Ltd, Unimark Developments and SKS Homes Ltd.",
  "Appia Development",
  "Canderel Residential and Townline",
  "Hansen Pacific",
  "Landa Global Properties",
  "Fabric Living",
  "Minglian Group",
  "OpenForm Properties",
  "Mortise Group of Companies",
  "Redekop Kroeker Development Inc.",
  "Ikonik Homes",
  "Landa Global Properties and Asia Standard Americas",
  "Tien Sher Group",
  "Millennium Development",
  "Create Properties",
  "Apcon Group",
  "Leone Homes",
  "Kunyuan International Group",
  "Red Cardinal Homes",
  "Reliance Properties",
  "Doxa Properties",
  "Gramercy Developments",
  "LionEstate Construction Ltd.",
  "Areva Living",
  "Anxin Projects",
  "Saint Group of Companies",
  "Brimming Development and SwissReal Group",
  "Northstar Development",
  "Ampri",
  "Polywest Homes",
  "Symphony Homes Limited",
  "Peterson and Concert Properties",
  "Konic Homes",
  "Aoyuan International",
  "Sian Development",
  "Fairborne Homes",
  "Delta Group",
  "The Jim Pattison Group",
  "Terraspherre",
  "Old Orchard Development",
  "Hawthorne Developments, Medallion Homes Inc. and Edge Construction and Design",
  "AREE Developments",
  "Zail Properties",
  "I4 Property Group",
  "Ivanhoé Cambridge",
  "Casa Mia Projects Ltd.",
  "Antaios Construction & Development Ltd",
  "Holborn",
  "1969 East 49th Avenue Ltd Partnership",
  "TopStream Group",
  "Kingborn Properties",
  "Valour Development Group and JB Group",
  "Kenstone Properties",
  "Bohan Properties",
  "Trillium Projects",
  "Redekop Ferrario Properties",
  "Conwest Developments",
  "First Track Development and Helmsman Projects",
  "Janda Group",
  "Sandhill Development",
  "Alabaster",
  "Boffo Developments Ltd",
  "GUD Group",
  "Reliance Properties and The Jim Pattison Group",
  "Thinkhome and Crescent Legacy Properties",
  "Novara Properties",
  "Anderson Square",
  "Aspac Developments and Three Shores",
  "Sterling Pacific Developments",
  "Otivo Developments",
  "Hayer Builders Group",
  "Ulmus Development",
  "Maple Leaf Homes",
  "Vanwell Homes",
  "EPIX Developments Ltd. and Circadian",
  "Origin Properties",
  "Wesmont",
  "Hampton Enterprises",
  "AMC Development",
  "Archwood Developments",
  "Laidler Group",
  "Quantum Properties",
  "Bassi Properties",
  "Concordia Properties",
  "Warwickshire Homes",
  "Nexus Development Group",
  "Bramor Developments",
  "Circadian and Allaire",
  "Atrium Group",
  "Allure Ventures Inc.",
  "Woodbridge Northwest",
  "Realco Properties",
  "Bovo Development LTD.",
  "Natcon Construction Services Ltd.",
  "Dema Developments",
  "Domus Homes and Platform Properties",
  "Shokai Canada",
  "Park Ridge Homes",
  "Brookfield Residential",
  "Darshan Co.",
  "Realco Properties and Narian",
  "Morningstar Homes",
  "Qualex-Landmark",
  "Everest Group",
  "Inspira",
  "Panorama West Group",
  "Naikoon Contracting Ltd.",
  "Oris",
  "Denna Homes",
  "Vicini Homes and Conwest Developments",
  "Townside Developments",
  "Allaire, Saturn 121 Construction Inc. and Headwater Projects",
  "Skyllen Pacific",
  "Kerr Properties",
  "Vanprop Investments Ltd",
  "Tavanco",
  "RBI Construction Group",
  "Canvas Impact Advisors",
  "Seamax Development Group",
  "North Arc Properties",
  "Cielle Properties",
  "Firm Developments",
  "Bolt Construction Ltd.",
  "Vertex Developments",
  "South Street Development Group and Alderbridge Way LP",
  "Bastion Development Corporation",
  "Quorus Properties",
  "Pacific New Homes",
  "EPIX Developments Ltd.",
  "Moody Park Homes",
  "Grosvenor, Modern Green Canada and Transca Real Estate Development",
  "Pakland Homes and Urban Era Builders and Developers Inc",
  "Modella Developments",
  "Manse Group Developments Ltd.",
  "Ansu Development",
  "Enera Enterprises",
  "Isle of Mann Property Group, Landmark West and Pollyco Group of Companies",
  "Natland Group",
  "Nexst Properties and Birudo Projects",
  "Edgar Development",
  "BM Group",
  "IMANI Development",
  "Captain Homes Ltd.",
  "PSquare Engineering and Construction Ltd.",
  "Avani Investment Group",
  "Cape Group",
  "Tomo Spaces",
  "Silver Star Enterprises Ltd.",
  "Hunter Park",
  "ML Emporio Properties Ltd.",
  "Pacific Northern Developments Ltd.",
  "Caliber Projects",
  "Falcon Homes",
  "Edena Development Inc.",
  "Circadian",
  "Brivia Group and Henson Developments",
  "Vanprop Investments Ltd and Bosa Properties",
  "Hathstauwk",
  "Matte Homes",
  "RDG Management Ltd",
  "Placemaker Communities Inc.",
  "Kadium Properties",
  "MetroVan",
  "Redekop Development Corporation",
  "Infinity Properties",
  "AULUME",
  "Aoyuan International and Three Shores",
  "Darshan Co. and LionEstate Construction Ltd.",
  "Kutak Development",
  "Lavern Developments",
  "Formwerks Boutique Properties",
  "MacLean Homes",
  "Genaris Properties and Oviedo Properties",
  "Darwin",
  "Pacific Vision Development",
  "Meritage Properties Ltd.",
  "Zenith Development",
  "WANSON GROUP",
  "LANDA GLOBAL",
  "ML EMPORIO PROPERTIES",
  "ZENTERRA",
  "PANAROMA WEST GROUP",
  "CENTURY GROUP",
  "THIND",
  "ADERA",
  "ALLURE VENTURES INC.",
  "PARK RIDGE HOMES",
  "FOUR SQUARE PROPERTIES",
  "STREETSIDE DEVELOPMENTS",
  "HIVE DEVELOPMENT",
  "PORTE COMMUNITIES",
  "VESTERRA PROPERTIES",
  "MARATHON HOMES",
  "AVANI INVESTMENT GROUP & BRIDGEWATER DEVELOPMENT CORPORATION",
  "SILVER STAR ENTERPRISES",
  "MOSAIC HOMES",
  "CONCORD PACIFIC",
  "MORTISE GROUP OF COMPANIES",
  "WHITETAIL HOMES",
  "JAYEN PROPERTIES",
  "YOUNG EARTH",
  "APCON GROUP",
  "GENARIS PROPERTIES & OVIEDO DEVELOPMENTS",
  "AOYUAN GROUP",
  "LIONESTATE CONSTRUCTION",
  "MANSE GROUP DEVELOPMENTS LTD.",
  "MERTIAGE PROPERTIES LTD.",
  "GRAMERCY DEVELOPMENTS",
  "MAINLAND DEVELOPMENTS",
  "ACEPOINT HOMES & HIS GRACE HOMES",
  "INSPIRA",
  "SIVIA CONSTRUCTION",
  "DARSHAN BUILDERS",
  "ELEGANT DEVELOPMENT",
  "DAWSON + SAWYER",
  "EVEREST GROUP",
  "VANQUISH HOMES",
  "BLUESKY PROPERTIES & BOSA PROPERTIES",
  "QUADRA HOMES",
  "CRESCENT CREEK HOMES",
  "PCC HOMES",
  "ZENITH DEVELOPMENT",
  "RED CONSTRUCTION",
  "CANADA WEST GROUP",
  "ANSU DEVELOPMENT",
  "EPTA DEVELOPMENT CORPORATION",
  "ALABASTER",
  "BUCCI",
  "WESGROUP",
  "GROSVENOR",
  "BRIVIA GROUP & HENSON DEVELOPMENTS",
  "WESTBANK & QUADREAL",
  "EPIX DEVELOPMENTS LTD. & CIRCADIAN DEVELOPMENTS LTD.",
  "FIRST TRACK DEVELOPMENT",
  "CRESSEY",
  "TERRA BLANKA & LOTUS LIVING GROUP",
  "CHARD DEVELOPMENT",
  "LISTRAOR",
  "AMACON",
  "SOLTERRA GROUP OF COMPANIES",
  "ONNI GROUP OF COMPANIES",
  "GEORGIA PACIFIC HOLDINGS CORP",
  "ULMUS DEVELOPMENT",
  "POLYGON HOMES",
  "MARCON",
  "DIMEX GROUP",
  "KAVAL PROPERTIES",
  "1969 EAST 49TH AVENUE LTD PARTNERSHIP",
  "VICINI HOMES & CONWEST",
  "AMC DEVELOPMENT",
  "AREE DEVELOPMENT",
  "ASIA STANDARD AMERICAS",
  "IFORTUNE",
  "SOUTH STREET DEVELOPMENT GROUP",
  "ANEEK DEVELOPMENT",
  "CONCERT",
  "BOVO DEVELOPMENT LTD.",
  "EVERBRIGHT PROPERTIES",
  "MATCHPOINT DEVELOPMENT",
  "HAWTHORNE DEVELOPMENTS, MEDALLION HOMES INC. & EDGE CONSTRUCTION AND DESIGN",
  "RENDITION DEVELOPMENTS",
  "BELFORD PROPERTIES",
  "SHOKAI CANADA",
  "PETERSON GROUP",
  "ANTHEM",
  "ARDOR DEVELOPMENT",
  "FULLY HOMES",
  "QUALEX-LANDMARK",
  "LAVERN DEVELOPMENTS",
  "VERTEX DEVELOPMENTS",
  "SIGHTLINE PROPERTIES",
  "RAR DEVELOPMENT",
  "PINNACLE INTERNATIONAL",
  "HON DEVELOPMENTS",
  "HANSEN PACIFIC",
  "ARIA PACIFIC DEVELOPMENT INC.",
  "VSP DEVELOPMENTS",
  "FASTMARK DEVELOPMENT CORP",
  "MINGLIAN HOLDING LTD.",
  "NOBLELAND DEVELOPMENTS",
  "EVERGLADE DEVELOPMENT",
  "RIAA DEVELOPMENT",
  "BRENHILL",
  "PENNYFARTHING HOMES",
  "GRYPHON DEVELOPMENT",
  "FORME DEVELOPMENT",
  "WAVE DEVELOPMENTS LTD.",
  "TOMO SPACES",
  "KILLARNEY GROUP",
  "RK DEVELOPMENT GROUP, REDEKOP KROEKER DEVELOPMENTS INC. & RF PROPERTIES",
  "ORIGIN PROPERTIES",
  "RELIANCE PROPERTIES & THE JIM PATTISON GROUP",
  "ROSANNI PROPERTIES",
  "VANWELL HOMES",
  "YUE HWA",
  "BEEDIE LIVING",
  "FOSTER LIVING",
  "CIRCADIAN DEVELOPMENTS LTD.",
  "DOMUS HOMES",
  "WOODBRIDGE HOMES",
  "STRAND",
  "ANNESLEY HOMES",
  "CANADA WEST GROUP",
  "FORMWERKS BOUTIQUE PROPERTIES",
  "VANHOME PROPERTIES INC.",
  "ZAKO DEVELOPMENT INC.",
  "N/A",
  "TOWNLINE, QUADREAL, & QUADREAL PROPERTY GROUP",
  "QUANTUM PROPERTIES",
  "CENTRED DEVELOPMENTS",
  "IKOR DEVELOPMENT",
  "OTIVO DEVELOPMENT GROUP",
  "VANSTRUCTION GROUP",
  "ARAGON",
  "AREVA LIVING",
  "ILLUMA DEVELOPMENT",
  "VINTOP DEVELOPMENT & DOMUS HOMES",
  "FOXRIDGE HOMES",
  "HAYER BUILDING GROUP",
  "ATRIUM GROUP",
  "NEW STORY HOMES INC.",
  "GARCHA HOMES",
  "WARWICKSHIRE HOMES",
  "APNA GROUP",
  "CASTLEHILL HOMES",
  "QUARRY ROCK DEVELOPMENTS",
  "JANDA GROUP",
  "ISLE OF MANN & LANDMARK WEST CONSTRUCTION",
  "PADDINGTON PROPERTIES",
  "BMG REAL ESTATE",
  "MANN GROUP",
  "QUANTERRA DEVELOPMENTS LTD.",
  "DIVERSE",
  "ATSMA CONSTRUCTION",
  "DIVINE HOMES LTD.",
  "HEINRICHS DEVELOPMENTS",
  "INFINITY PROPERTIES",
  "REDEKOP FAYE & REDEKOP REDDALE GROUP",
  "AB WALL",
  "KERKHOFF-DEVELOP-BUILD",
  "WESTBOW",
  "WOODLOCK DEVELOPMENTS",
  "QUAD CITY REAL ESTATE GROUP & ELEVEN OAKS DEVELOPMENT",
  "PLATINUM GROUP",
  "AZURE GROUP",
  "DEMA DEVELOPMENTS",
  "RAICON",
  "GATEHOUSE DESIGN + DEVELOPMENTS",
  "EPIC HOMES",
  "MACLEAN HOMES",
  "ROYALE PROPERTIES",
  "PACIFIC VISION DEVELOPMENT",
  "FALCON HOMES",
  "CONCORDIA PROPERTIES",
  "LANDMARK PREMIERE PROPERTIES",
  "OVIEDO DEVELOPMENTS",
  "RDG MANAGEMENT LTD.",
  "WESTCORP",
  "PACIFIC HILLS DEVELOPMENTS & MILKAI DEVELOPMENTS",
  "BIANCO DEVELOPMENT",
  "WEST FRASER DEVELOPMENTS LTD.",
  "THIND",
  "ALVAIR GROUP",
  "TANGERINE DEVELOPMENTS",
  "SIVIA CONSTRUCTION",
];

export default developers;
