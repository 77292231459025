import React, { Component } from "react";
import {
  Select,
  Button,
  Form,
  Pagination,
  Slider,
  InputNumber,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import cities from "../filter-data/cities";
import developers from "../filter-data/developers";
import projects from "../filter-data/projects";

const { Option } = Select;

class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredProperties: [], // For storing filtered results
      count: 0,
      currentPage: 1,
      pageSize: 20, // Number of items per page
      propertyType: "",
      city: "",
      bedrooms: "",
      baths: "",
      squareFeetRange: [], // Initial range for square footage
      developer: "",
      status: "",
      proj_name: "",
      expandedGroups: {},
      sortBy: "",
      min_price: 0,
      max_price: 999999999,
      include_undefine_prices: false,
    };
  }

  componentDidMount() {
    this.fetchProperties(); // Fetch properties on mount
  }

  fetchProperties = async (page = 1) => {
    const {
      propertyType,
      city,
      bedrooms,
      baths,
      squareFeetRange,
      developer,
      min_price,
      max_price,
      status,
      proj_name,
      sortBy,
      include_undefine_prices,
    } = this.state;

    const cityParam = city.length ? `&city=${city.join(".")}` : "";
    const projParam = proj_name.length
      ? `&proj_name=${proj_name.join(",")}`
      : "";

    const url = `https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/all/group/properties/?page=${page}${cityParam}${projParam}${
      propertyType ? `&property_type=${propertyType}` : ""
    }${bedrooms ? `&beds=${bedrooms}` : ""}${baths ? `&baths=${baths}` : ""}${
      squareFeetRange && squareFeetRange.length > 0
        ? `&min_sqft=${squareFeetRange[0]}&max_sqft=${squareFeetRange[1]}`
        : ""
    }${`&min_price=${min_price}&max_price=${max_price}`}${
      developer ? `&developer=${developer}` : ""
    }${sortBy ? `&sortBy=${sortBy}` : ""}${status ? `&status=${status}` : ""}${
      include_undefine_prices
        ? `&include_undefine_prices=${include_undefine_prices}`
        : ""
    }`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        filteredProperties: data.results, // Set initial filtered data
        count: data.count,
        currentPage: page,
      });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  handlePageChange = (page) => {
    this.fetchProperties(page);
  };

  handleSubmit = async (values) => {
    this.setState(
      {
        propertyType: values.propertyType || "",
        city: values.city || "",
        bedrooms: values.bedrooms || "",
        baths: values.baths || "",
        squareFeetRange: values.squareFeetRange || [],
        status: values.status || "",
        developer: values.developer || "",
        proj_name: values.proj_name || "",
        sortBy: values.sortBy || "",
        min_price: values.min_price || 0,
        max_price: values.max_price || 999999999,
        include_undefine_prices: values.include_undefine_prices || false,
        currentPage: 1,
      },
      () => {
        this.fetchProperties();
      }
    );
  };
  handleClearFilters = () => {
    this.setState({
      propertyType: "",
      city: "",
      bedrooms: "",
      baths: "",
      squareFeetRange: [],
      status: "",
      developer: "",
      proj_name: "",
      sortBy: "",
      min_price: 0,
      max_price: 999999999,
      include_undefine_prices: false,
      currentPage: 1,
    });
    window.location.reload();
  };

  toggleGroup = (index) => {
    this.setState((prevState) => ({
      expandedGroups: {
        ...prevState.expandedGroups,
        [index]: !prevState.expandedGroups[index],
      },
    }));
  };

  phaseMapping = {
    PRE: "PRECONSTRUCTION",
    CON: "CONSTRUCTION",
    COMP: "COMPLETED",
    REG: "REGISTERING",
    SOLD: "SOLD",
    SELL: "SELLING",
  };

  propertyTypeColors = {
    APARTMENT: "#9C27B0", // Vivid Purple
    CONDO: "#FFB300", // Rich Amber
    House: "#FF6D00", // Vibrant Orange
    PENTHOUSE: "#FF4081", // Hot Pink
    TOWNHOUSE: "#8E24AA", // Bright Violet
  };

  render() {
    const {
      filteredProperties,
      currentPage,
      count,
      pageSize,
      squareFeetRange,
      min_price,
      expandedGroups,
      max_price,
    } = this.state;
    return (
      <div className="">
        <div className="container" style={{ maxWidth: "95%" }}>
          <div className="row">
            <div className="col-lg-12 mt-1">
              <div className="ltn__car-dealer-form-tab ">
                <div className="tab-content bg-white box-shadow-1 position-relative pb-10">
                  <div className="tab-pane fade active show">
                    <div className="car-dealer-form-inner">
                      <Form
                        onFinish={this.handleSubmit}
                        className="ltn__car-dealer-form-box row"
                        initialValues={{
                          include_undefine_prices: false, // Sets initial checked state
                        }}
                      >
                        <Form.Item
                          name="propertyType"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Property Type"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="Apartment">Apartments</Option>
                            <Option value="Condo">Condos</Option>
                            <Option value="Townhouse">Townhouses</Option>
                            <Option value="House">Single Family Houses</Option>
                            <Option value="Penthouse">Penthouses</Option>
                            <Option value="all">Other</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item name="city" className="col-lg-3 col-md-6">
                          <Select
                            mode="multiple"
                            placeholder="City"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            {cities.map((city) => (
                              <Option key={city} value={city}>
                                {city}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="bedrooms"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="No Of Bedrooms"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="0.0">0</Option>
                            <Option value="0.5">0+</Option>
                            <Option value="1.0">1</Option>
                            <Option value="1.5">1+</Option>
                            <Option value="2.0">2</Option>
                            <Option value="2.5">2+</Option>
                            <Option value="3.0">3</Option>
                            <Option value="3.5">3+</Option>
                            <Option value="4.0">4</Option>
                            <Option value="4.5">4+</Option>
                            <Option value="5.0">5</Option>
                            <Option value="5.5">5+</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="baths"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="No Of Baths"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="0.0">0</Option>
                            <Option value="0.5">0+</Option>
                            <Option value="1.0">1</Option>
                            <Option value="1.5">1+</Option>
                            <Option value="2.0">2</Option>
                            <Option value="2.5">2+</Option>
                            <Option value="3.0">3</Option>
                            <Option value="3.5">3+</Option>
                            <Option value="4.0">4</Option>
                            <Option value="4.5">4+</Option>
                            <Option value="5.0">5</Option>
                            <Option value="5.5">5+</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="developer"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Developer"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            {developers.map((developer) => (
                              <Option key={developer} value={developer}>
                                {developer}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="proj_name"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            mode="multiple"
                            placeholder="Project Name"
                            allowClear
                            showSearch
                            style={{ minHeight: "25px" }}
                          >
                            {projects.map((project) => (
                              <Option key={project} value={project}>
                                {project}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="status"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Status"
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="PRE">PRE-CONSTRUCTION</Option>
                            <Option value="CON">CONSTRUCTION</Option>
                            <Option value="COMP">COMPLETED</Option>
                            <Option value="REG">REGISTERING</Option>
                            <Option value="SOLD">SOLD</Option>
                            <Option value="SELL">SELLING</Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="sortBy"
                          className="ltn__car-dealer-form-item col-lg-3 col-md-6"
                        >
                          <Select
                            placeholder="Sort By "
                            allowClear
                            style={{ minHeight: "25px" }}
                          >
                            <Option value="1">Min Price</Option>
                            <Option value="2">Max Price </Option>
                            <Option value="3">Min Square Feet </Option>
                            <Option value="4">Max Square Feet </Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="squareFeetRange"
                          label="Square Feet"
                          className="col-lg-3 col-md-6"
                        >
                          <Slider
                            range
                            defaultValue={[0, 9999]}
                            max={9999}
                            step={1}
                            onChange={(value) =>
                              this.setState({ squareFeetRange: value })
                            }
                            value={squareFeetRange}
                          />
                        </Form.Item>
                        <Form.Item className="col-lg-3 col-md-6">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Item
                              name="min_price"
                              noStyle
                              rules={[
                                {
                                  type: "number",
                                  min: 0,
                                  message: "Minimum price must be at least 0",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Minimun Price"
                                min={0}
                                max={999999999}
                                style={{ marginRight: "10px", width: "100%" }}
                                value={min_price ? min_price : 0}
                              />
                            </Form.Item>
                            <Form.Item
                              name="max_price"
                              noStyle
                              rules={[
                                {
                                  type: "number",
                                  max: 999999999,
                                  message:
                                    "Maximum price must be at most 999999999",
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Max Price"
                                min={0}
                                max={999999999}
                                style={{ width: "100%" }}
                                value={max_price ? max_price : 999999999}
                              />
                            </Form.Item>
                          </div>
                        </Form.Item>

                        <Form.Item
                          name="include_undefine_prices"
                          valuePropName="checked"
                          className="col-lg-3 col-md-6"
                        >
                          <Checkbox style={{ width: "100%" }}>
                            Inlude N/A
                          </Checkbox>
                        </Form.Item>
                        <div className="button-group d-flex justify-content col-lg-3 col-md-6">
                          <Form.Item className="car-price-filter-range">
                            <Button
                              htmlType="submit"
                              className="btn-outline-primary text-uppercase pt-1 text-dark"
                            >
                              Search Listings
                            </Button>
                            <Button
                              style={{ marginLeft: "10px" }}
                              htmlType="button"
                              className="btn-outline-primary text-uppercase pt-1 text-dark"
                              onClick={this.handleClearFilters} // Add onClick handler
                            >
                              Clear Filter
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5" style={{ maxWidth: "95%" }}>
          <br />
          <br />
          <div className="row">
            <p style={{ fontSize: "14px" }}>
              Price changes:&nbsp;
              <span class="color-box small-change"></span>
              <small style={{ fontSize: "14px" }}>
                Below the median. Sq. Ft.: Larger than median
              </small>{" "}
              &nbsp;
              <span class="color-box mid-change"></span>
              <small style={{ fontSize: "14px" }}>
                Represents both price and square footage at or near the median
              </small>
              &nbsp;
              <span class="color-box max-change"></span>
              <small style={{ fontSize: "14px" }}>
                Above the median. Square Footage: Smaller than median.
              </small>
            </p>
            <div className="col-lg-12 shadow">
              <div
                className="table-responsive "
                style={{ background: "white" }}
              >
                <table className="table mt-1 " style={{ background: "white" }}>
                  <thead className="text-left" style={{ background: "white" }}>
                    <tr style={{ background: "white" }}>
                      <th>Action</th>
                      <th>Project</th>
                      <th>Price</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>SQFT</th>
                      <th>Beds</th>
                      <th>Baths</th>
                      <th>PricePerSQ</th>
                      <th>Status</th>
                      <th>CompletionDate</th>
                      <th>Developer</th>
                      <th>PropertyType</th>
                      <th>Units</th>
                      <th>Stories</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-secondary text-left"
                    style={{ background: "white" }}
                  >
                    {filteredProperties.map((propertyGroup, index) => (
                      <React.Fragment key={index}>
                        {/* Display only the first property by default */}
                        {propertyGroup.slice(0, 1).map((property) => (
                          <tr
                            key={property.id}
                            style={{ cursor: "pointer", background: "white" }}
                          >
                            <td>
                              {propertyGroup.length > 1 && (
                                <button
                                  style={{
                                    fontSize: "22px",
                                    width: "30px", // Set a width
                                    height: "30px", // Set the same height
                                    borderRadius: "50%", // Make it round
                                    backgroundColor: "#0c579b", // Optional: add background color
                                    border: "none", // Optional: remove border
                                    cursor: "pointer", // Change cursor to pointer
                                    display: "flex", // Center the content
                                    alignItems: "center", // Center vertically
                                    justifyContent: "center", // Center horizontally
                                    color: "white",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent row's onClick event
                                    this.toggleGroup(index);
                                  }}
                                >
                                  {expandedGroups[index] ? "-" : "+"}
                                </button>
                              )}
                            </td>
                            <td className="text-left table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                <img
                                  src={property.thumbnail}
                                  alt={property.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "8px",
                                  }}
                                />{" "}
                                <br />
                                <u>{property.name} </u>
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link
                                to={`/product-details/${property.id}`}
                                className={
                                  property.price === 0
                                    ? ""
                                    : property.price > 3000000
                                    ? "dark-red"
                                    : property.price >= 2000000
                                    ? "blue"
                                    : "green"
                                }
                              >
                                {property.price === 0
                                  ? "TBD"
                                  : `$${property.price.toLocaleString()}`}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {property.address}
                              </Link>
                            </td>
                            <td>
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {property.city}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link
                                to={`/product-details/${property.id}`}
                                className={
                                  property.sq === 0
                                    ? ""
                                    : Number(property.sq) > 1000
                                    ? "blue"
                                    : Number(property.sq) <= 1000
                                    ? "dark-red"
                                    : "green"
                                }
                              >
                                {property.sq === 0
                                  ? "TBD"
                                  : `${Number(property.sq).toLocaleString()}`}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {property.beds}{" "}
                              </Link>
                            </td>
                            <td>
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {property.baths}{" "}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link
                                to={`/product-details/${property.id}`}
                                className={
                                  property.avg_sq_price === 0
                                    ? ""
                                    : Number(property.avg_sq_price) > 3000000
                                    ? "dark-red"
                                    : Number(property.avg_sq_price) >= 2000000
                                    ? "blue"
                                    : "green"
                                }
                              >
                                {property.avg_sq_price === 0
                                  ? "TBD"
                                  : `$${parseFloat(
                                      property.avg_sq_price
                                    ).toLocaleString()}`}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {this.phaseMapping[property.phase] ||
                                  property.phase}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              <Link
                                to={`/product-details/${property.id}`}
                                style={{
                                  color:
                                    property.completion_date !== "TBD"
                                      ? "blue"
                                      : "inherit",
                                }}
                              >
                                {property.completion_date === "TBD"
                                  ? "TBD"
                                  : dayjs(property.completion_date).format(
                                      "YYYY-MM-DD"
                                    )}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                {property.developer &&
                                property.developer !== "Unknown Developer"
                                  ? property.developer
                                  : "TBD"}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              <Link
                                to={`/product-details/${property.id}`}
                                style={{
                                  color:
                                    this.propertyTypeColors[
                                      property.property_type
                                    ] || "#333", // Default color if not matched
                                  fontWeight: "bold", // Bold text for better visibility
                                }}
                              >
                                {property.property_type}
                              </Link>
                            </td>

                            <td className="table-data-item">
                              {" "}
                              <Link
                                to={`/product-details/${property.id}`}
                                style={{
                                  color: "green",
                                }}
                              >
                                {property.units}{" "}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link
                                to={`/product-details/${property.id}`}
                                style={{
                                  color: "blue",
                                }}
                              >
                                {property.stories}{" "}
                              </Link>
                            </td>
                            <td className="table-data-item">
                              {" "}
                              <Link to={`/product-details/${property.id}`}>
                                <u className="text-success">
                                  <EyeOutlined />
                                </u>
                              </Link>
                            </td>
                          </tr>
                        ))}
                        {/* Show additional properties if the group is expanded */}
                        {expandedGroups[index] &&
                          propertyGroup.slice(1).map((property) => (
                            <tr
                              key={property.id}
                              style={{ cursor: "pointer", background: "white" }}
                            >
                              <td></td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  <img
                                    src={property.thumbnail}
                                    alt={property.name}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "8px",
                                    }}
                                  />{" "}
                                  <br />
                                  <u>{property.name} </u>
                                </Link>
                              </td>
                              <td className="table-data-item">
                                <Link
                                  to={`/product-details/${property.id}`}
                                  className={
                                    property.price === 0
                                      ? ""
                                      : property.price > 3000000
                                      ? "dark-red"
                                      : property.price >= 2000000
                                      ? "blue"
                                      : "green"
                                  }
                                >
                                  {property.price === 0
                                    ? "TBD"
                                    : `$${property.price.toLocaleString()}`}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {property.address}
                                </Link>
                              </td>
                              <td>
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {property.city}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link
                                  to={`/product-details/${property.id}`}
                                  className={
                                    property.sq === 0
                                      ? ""
                                      : property.sq === "TBD"
                                      ? ""
                                      : Number(property.sq) > 1000
                                      ? "blue"
                                      : Number(property.sq) <= 1000
                                      ? "dark-red"
                                      : "green"
                                  }
                                >
                                  {property.sq === 0
                                    ? "TBD"
                                    : `${Number(property.sq).toLocaleString()}`}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {property.beds}{" "}
                                </Link>
                              </td>
                              <td>
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {property.baths}{" "}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link
                                  to={`/product-details/${property.id}`}
                                  className={
                                    property.avg_sq_price === 0
                                      ? ""
                                      : Number(property.avg_sq_price) > 3000000
                                      ? "dark-red"
                                      : Number(property.avg_sq_price) >= 2000000
                                      ? "blue"
                                      : "green"
                                  }
                                >
                                  {property.avg_sq_price === 0
                                    ? "TBD"
                                    : `$${parseFloat(
                                        property.avg_sq_price
                                      ).toLocaleString()}`}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {this.phaseMapping[property.phase] ||
                                    property.phase}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                <Link
                                  to={`/product-details/${property.id}`}
                                  style={{
                                    color:
                                      property.completion_date !== "TBD"
                                        ? "blue"
                                        : "inherit",
                                  }}
                                >
                                  {property.completion_date === "TBD"
                                    ? "TBD"
                                    : dayjs(property.completion_date).format(
                                        "YYYY-MM-DD"
                                      )}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  {property.developer &&
                                  property.developer !== "Unknown Developer"
                                    ? property.developer
                                    : "TBD"}
                                </Link>
                              </td>
                              <td className="table-data-item">
                              <Link
                                to={`/product-details/${property.id}`}
                                style={{
                                  color:
                                    this.propertyTypeColors[
                                      property.property_type
                                    ] || "#333", // Default color if not matched
                                  fontWeight: "bold", // Bold text for better visibility
                                }}
                              >
                                {property.property_type}
                              </Link>
                            </td>

                              <td className="table-data-item">
                                {" "}
                                <Link
                                  to={`/product-details/${property.id}`}
                                  style={{
                                    color: "green",
                                  }}
                                >
                                  {property.units}{" "}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link
                                  to={`/product-details/${property.id}`}
                                  style={{
                                    color: "blue",
                                  }}
                                >
                                  {property.stories}{" "}
                                </Link>
                              </td>
                              <td className="table-data-item">
                                {" "}
                                <Link to={`/product-details/${property.id}`}>
                                  <u className="text-success">
                                    <EyeOutlined />
                                  </u>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-12 d-flex justify-content-center">
              {/* Pagination controls */}
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={count}
                onChange={this.handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Availability;
