import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import dayjs from "dayjs";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

class ShopDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      propertyDetails: {
        latitude: 0, // Initial value
        longitude: 0, // Initial value
      },
      mapLoaded: false,
      hoverMessageIndex: null,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    const url = `https://moovmynt-backend-7a58dd9e9029.herokuapp.com/api/listings/property/details/${id}/`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => this.setState({ propertyDetails: data }))
      .catch((error) => this.setState({ error }));
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the latitude or longitude has changed and is not zero
    const { latitude, longitude } = this.state.propertyDetails;
    if (
      (latitude !== prevState.propertyDetails.latitude && latitude !== 0) ||
      (longitude !== prevState.propertyDetails.longitude && longitude !== 0)
    ) {
      this.setState({ mapLoaded: true });
    }
  }

  // Method to handle hover
  handleMouseEnter = (index) => {
    this.setState({ hoverMessageIndex: index });
  };

  handleMouseLeave = () => {
    this.setState({ hoverMessageIndex: null });
  };
  render() {
    const { propertyDetails, mapLoaded, hoverMessageIndex } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    const position = [
      propertyDetails?.latitude || "49.1392858",
      propertyDetails?.longitude || "-122.8415711",
    ];

    return (
      <div className="ltn__shop-details-area pb-10 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link to="#">Featured</Link>
                    </li>
                    <li className="ltn__blog-category">
                      <Link className="bg-orange" to="#">
                        {propertyDetails?.property_type}
                      </Link>
                    </li>
                  </ul>
                </div>

                <img
                  src={propertyDetails.thumbnail}
                  style={{ width: "100%", height: "50%", marginRight: 8 }}
                />
                <br />
                <br />
                <div className="text-left">
                  <h1>
                    {propertyDetails?.name} | {propertyDetails?.property_type} |{" "}
                    {propertyDetails.developer &&
                    propertyDetails.developer !== "Unknown Developer"
                      ? propertyDetails.developer
                      : "TBD"}
                  </h1>
                </div>

                <label>
                  <span className="text-info">
                    <i className="flaticon-pin" />
                  </span>{" "}
                  {propertyDetails?.address} , {propertyDetails?.city}
                </label>
                <h4 className="title-2">Description</h4>
                <p>
                  {propertyDetails?.description !== "None"
                    ? propertyDetails.description
                    : "To the left is the modern kitchen with central island, leading through to the unique breakfast family room which features glass walls and doors out onto the garden and access to the separate utility room."}
                </p>

                <h4 className="title-2">Property Detail</h4>
                <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                  <ul className="property-list">
                    <li className="property-item">
                      <i className="fas fa-id-badge property-icon"></i>
                      <label>Property ID:</label>
                      <span>{propertyDetails?.id}</span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-building property-icon"></i>
                      <label>Developer:</label>
                      <span>
                        {propertyDetails.developer &&
                        propertyDetails.developer !== "Unknown Developer"
                          ? propertyDetails.developer
                          : "TBD"}
                      </span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-ruler-combined property-icon"></i>
                      <label>Area:</label>
                      <span>{propertyDetails?.sq} sqft</span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-bed property-icon"></i>
                      <label>Beds:</label>
                      <span>{propertyDetails?.beds}</span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-bath property-icon"></i>
                      <label>Baths:</label>
                      <span>{propertyDetails?.baths}</span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-cogs property-icon"></i>
                      <label>Units:</label>
                      <span>{propertyDetails?.units}</span>
                    </li>
                  </ul>
                  <ul className="property-list">
                    <li className="property-item">
                      <i className="fas fa-layer-group property-icon"></i>
                      <label>Stories:</label>
                      <span>{propertyDetails?.stories}</span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-link property-icon"></i>
                      <label>Website:</label>
                      <span>
                        <a
                          href={propertyDetails?.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {propertyDetails?.website}
                        </a>
                      </span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-dollar-sign property-icon"></i>
                      <label>Price:</label>
                      <span>
                        {propertyDetails.price === 0
                          ? "TBD"
                          : `$${propertyDetails.price}`}
                      </span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-calendar property-icon"></i>
                      <label>Year built:</label>
                      <span>
                        {propertyDetails.completion_date === "TBD"
                          ? "TBD"
                          : dayjs(propertyDetails.completion_date).format(
                              "YYYY-MM-DD"
                            )}
                      </span>
                    </li>
                    <li className="property-item">
                      <i className="fas fa-check-circle property-icon"></i>
                      <label>Property Status:</label>
                      <span className="status-for-sale">For Sale</span>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Facts and Features</h4>
                <div className="property-detail-feature-list clearfix mb-45">
                  <ul>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-bed property-icon" />
                        <div>
                          <h6>Master Bed Room</h6>
                          <small>
                            {propertyDetails?.master_bed_sq_ft
                              ? propertyDetails?.master_bed_sq_ft
                              : "TBD"}{" "}
                            feet
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-umbrella-beach property-icon" />
                        <div>
                          <h6>Rooftop Terrace</h6>
                          <small>
                            {propertyDetails?.rooftop_terrace
                              ? propertyDetails?.rooftop_terrace
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-users property-icon" />
                        <div>
                          <h6>Guest Suite</h6>
                          <small>
                            {propertyDetails?.guest_suite
                              ? propertyDetails?.guest_suite
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-utensils property-icon" />
                        <div>
                          <h6>Dining Area</h6>
                          <small>
                            {propertyDetails?.dining_area
                              ? propertyDetails?.dining_area
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-couch property-icon" />
                        <div>
                          <h6>Social Lounge</h6>
                          <small>
                            {propertyDetails?.social_lounge
                              ? propertyDetails?.social_lounge
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-dumbbell property-icon" />
                        <div>
                          <h6>Gym</h6>
                          <small>
                            {propertyDetails?.gym
                              ? propertyDetails?.gym
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-leaf property-icon" />
                        <div>
                          <h6>Garden</h6>
                          <small>
                            {propertyDetails?.garden
                              ? propertyDetails?.garden
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="property-detail-feature-list-item">
                        <i className="fas fa-car property-icon" />
                        <div>
                          <h6>Parking</h6>
                          <small>
                            {propertyDetails?.park
                              ? propertyDetails?.park
                              : "TBD"}
                          </small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <h4 className="title-2">Location</h4>
                <div className="property-details-google-map mb-60">
                  {mapLoaded ? (
                    <MapContainer
                      center={position}
                      zoom={13}
                      style={{ height: "400px", width: "100%" }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={position}>
                        <Popup>
                          <img
                            src={propertyDetails.thumbnail}
                            alt={propertyDetails.name}
                            style={{
                              width: "100%",
                              height: "50%",
                              marginTop: 15,
                              marginBottom: 10,
                              marginLeft: 1,
                            }}
                          />
                          <br />
                          Property: {propertyDetails.name}
                          <br />
                          price: $ {propertyDetails.price}
                          <br />
                          Type: {propertyDetails.property_type}
                          <br />
                          Developer: {propertyDetails.developer}
                          <br />
                          City: {propertyDetails.city}
                          <br />
                          Location: {propertyDetails.address}
                        </Popup>
                      </Marker>
                    </MapContainer>
                  ) : (
                    <div>Loading map...</div>
                  )}
                </div>
                {/* Floor Plans */}
                {propertyDetails?.floor_plans &&
                  propertyDetails?.floor_plans.length > 0 && (
                    <>
                      <h4 className="title-2">Floor Plans</h4>
                      <div className="floor-plans mt-3 row">
                        {propertyDetails.floor_plans.map((floorPlan, index) => (
                          <div
                            key={floorPlan.id}
                            className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center p-4"
                          >
                            <div
                              className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center"
                              style={{
                                padding: "20px",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                              }}
                              onMouseEnter={() => this.handleMouseEnter(index)}
                              onMouseLeave={this.handleMouseLeave}
                            >
                              <div className="product-img go-top text-center pt-3">
                                <img
                                  src={floorPlan.external_url}
                                  alt={floorPlan.name}
                                  style={{
                                    width: "90%",
                                    height: "auto",
                                    marginLeft: "5%",
                                    filter: "blur(3px)",
                                  }}
                                  draggable="false"
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              </div>
                              <div className="product-info mt-2">
                                <h2
                                  className="product-title go-top"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#333",
                                    lineHeight: "1.5",
                                  }}
                                >
                                  {floorPlan.name}
                                </h2>
                              </div>
                              {hoverMessageIndex === index && (
                                <div
                                  className="hover-message text-center"
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <a
                                    href="https://moovmynt-backend-7a58dd9e9029.herokuapp.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Login To view Plan Details
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopDetails;
